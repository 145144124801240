<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消编辑?', '提示', { type: 'warning' }).then(res => $router.back())" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <div class="form-body" style="padding-top: 60rem; padding-left: 60rem">
<!--      <el-form ref="form" :model="formData" label-width="200rem" :rules="rules">-->
      <el-form ref="form" :model="formData" label-width="200rem">
        <el-form-item label="学生生日">
          <div class="flex flex-align">
            <p class="mr-20 c6">生日历法</p>
            <div>
              <el-radio @change="$refs.form.clearValidate(['birthday_y', 'birthday_n'])" v-model="formData.birthday_type" v-for="item in birthdayOptions" :label="item.label">{{ item.text }}
              </el-radio>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="formData.birthday_type === 1" label="输入生日日期" prop="birthday_y">
          <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="formData.birthday_y" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="formData.birthday_type === 0" label="输入生日日期" prop="birthday_n">
          <el-input v-model="formData.birthday_n" maxlength="30" placeholder="请输入生日日期"></el-input>
        </el-form-item>
        <el-form-item label="家长银行账户" prop="parent_bank">
          <el-input v-model="formData.parent_bank" maxlength="30" placeholder="请输入家长银行账户"></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="bank_name">
          <el-input v-model="formData.bank_name" placeholder="请输入开户行"></el-input>
        </el-form-item>
        <el-form-item label="开户人" prop="bank_user">
          <el-input v-model="formData.bank_user" maxlength="30" placeholder="请输入开户人"></el-input>
        </el-form-item>
        <el-form-item label="校服尺码" prop="preselection_uniform_size">
          <el-select :popper-append-to-body="false" v-model="formData.preselection_uniform_size">
            <el-option v-for="item in clothingOptions" :key="item.value" :label="item.text" :value="item.value">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="是否为培优生" required="" prop="is_training" :rules="{required:true,message:'请选择是否为培优生'}">
          <el-select v-model="formData.is_training" :popper-append-to-body="false">
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <template v-if="dataCont.department_head === 1">
          <el-form-item v-if="dataCont.pay_status === 0" label="是否取消订单" prop="status">
            <el-select :popper-append-to-body="false" v-model="formData.status">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option></el-select>
          </el-form-item>
          <el-form-item v-else-if="dataCont.pay_status === 2" label="是否取消订单" prop="status">
            <el-input style="display: none" v-model="formData.status"></el-input>
            <p>是</p>
          </el-form-item>
          <el-form-item v-else="" label="是否取消订单" prop="status">
            <el-input style="display: none" v-model="formData.status"></el-input>
            <p>{{ dataCont.pay_status_text }}</p>
          </el-form-item>
          <el-form-item label="面试评价（非）">
            <el-input resize="none" style="width: 790rem" type="textarea" placeholder="请输入" rows="8" maxlength="300" show-word-limit="" v-model="formData.interview_assessment"></el-input>
          </el-form-item>
          <el-form-item label="面试结果凭证截图（非）">
            <!--            <uploadImg @change="handleChange" :value="formData.interview_assessment_image"></uploadImg>-->
            <!--  -->
            <input type="file" ref="imgInput" multiple="" accept="image/png, image/jpeg, image/jpg" v-show="false" @change="addImg">
            <el-button class="replace-img" style="margin-bottom: 12rem" @click="btnClick">点击上传</el-button>
            <el-upload accept="image/png, image/jpeg, image/jpg" :before-upload="imgUploadBefore" :headers="headers" :http-request="uploadImg" multiple="" :limit="5" :file-list="formData.interview_assessment_image" :on-remove="imgUploadRemove" action="http://www.baidu.com" list-type="picture-card" name="image">
              <i class="el-icon-plus"></i>
            </el-upload>
            <p style="color: #999">（请上传5张及以内小于20M的png/jpg格式的图片）</p>
          </el-form-item>
        </template>
      </el-form>
    </div>


</edit-template>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  components: {uploadImg},
  data() {
    return {
      dataCont: '',
      //生日历法配置
      birthdayOptions: [
        {label: 1, text: '公历'},
        {label: 0, text: '农历'}
      ],
      statusOptions: [
        {label: '否', value: 0},
        {label: '是', value: 1}
      ],
      clothingOptions: [
        {text: 100, value: '100'},
        {text: 110, value: '110'},
        {text: 115, value: '115'},
        {text: 120, value: '120'},
        {text: 125, value: '125'},
        {text: 130, value: '130'},
        {text: 135, value: '135'},
        {text: 140, value: '140'},
        {text: 145, value: '145'},
        {text: '150（XS）', value: '150（XS）'},
        {text: '155（S）', value: '155（S）'},
        {text: '160（M）', value: '160（M）'},
        {text: '165（L）', value: '165（L）'},
        {text: '170（XL）', value: '170（XL）'},
        {text: '175（2XL）', value: '175（2XL）'},
        {text: '180（3XL）', value: '180（3XL）'},
        {text: '185（4XL）', value: '185（4XL）'},
        {text: '190（5XL）', value: '190（5XL）'},
        {text: 195, value: '195'},
        {text: 200, value: '200'}
      ],
      orderOptions: [
        {label: '待支付', value: 0},
        {label: '已支付', value: 1},
        {label: '显示取消', value: 2},
        {label: '已退款', value: 3},
        {label: '取消支付', value: 4},
        {label: '预付款支付失败', value: 5}
      ],

      // 表单数据
      formData: {
        birthday_type: 1,
        birthday_y: '',
        birthday_n: '',
        parent_bank: '',
        bank_name:null,
        bank_user:null,
        preselection_uniform_size: '',
        status: 0,
        interview_assessment: '',
        interview_assessment_image: [],
        is_training:null
      },
      // 表单验证规则
      rules: {
        birthday_y: [{required: true, message: '请选择生日日期', trigger: 'change'}],
        birthday_n: [{required: true, message: '请输入生日日期', trigger: 'change'}],
        parent_bank: [{required: true, message: '请输入家长银行账户', trigger: 'change'}],
        preselection_uniform_size: [{required: true, message: '请选择校服尺码', trigger: 'change'}],
        status: [{required: true, message: '请选择是否取消订单', trigger: 'change'}]
      },
      startDefaultValue: new Date('2000-1-1 00:00:00'),
      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleChange(val) {
      this.formData.interview_assessment_image = val
    },
    getSchoolConfig() {
      this.$_axios.get('site/school').then(res => {
        this.schoolConfig = res.data.data
      })
    },

    getPeriodConfig() {
      this.$_axios2.get('api/evaluation/counseling-setting/term').then(res => {
        if (res.data.status === 0) {
          this.periodConfig = res.data.data.list
        }
      })
    },

    getData() {
      this.$_register.get('api/recruit/fresh-graduate/show?order_id=' + this.$route.query.id).then(res => {
        let data = res.data.data
        this.dataCont = {...data}
        this.formData.birthday_type = data.birthday_type
        this.formData.parent_bank = data.parent_bank
        this.formData.preselection_uniform_size = data.preselection_uniform_size
        this.formData.interview_assessment = data.interview_assessment
        this.formData.bank_name = data.bank_name
        this.formData.bank_user = data.bank_user
        this.formData.is_training = data.is_training
        console.log(typeof data.is_training)
        if(data.interview_assessment_image.length){
          this.formData.interview_assessment_image = data.interview_assessment_image.map(item => {
            return {
              name: item,
              url: item
            }
          })
        }else{
          this.formData.interview_assessment_image = []
        }

        if (data.birthday_type === 1) {
          this.formData.birthday_y = res.data.data.birthday_ext
        } else {
          this.formData.birthday_n = res.data.data.birthday_ext
        }
      })
    },

    //图片上传前的回调
    imgUploadBefore(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 20MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M
    },

    //删除图片回调
    imgUploadRemove(file, fileList) {
      this.formData.interview_assessment_image = fileList
    },

    //点击图片时的回调
    imgUploadPreview(file) {
      this.dialogImageUrl = file[0].url
      this.dialogVisible = true
    },

    uploadImg(file) {
      let boo = this.imgUploadBefore(file.file)
      if (boo) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          customClass: 'img-upload-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$uploadToOSS_1(file.file, 3)
            .then(res => {
              this.formData.interview_assessment_image.push({
                name: file.file.name,
                url: res
              })
            })
            .finally(() => {
              loading.close()
            })
      }
    },

    btnClick() {
      this.$refs.imgInput.value = ''
      this.$refs.imgInput.click()
    },

    addImg(e) {
      let list = e.target.files
      let count = 5 - this.formData.interview_assessment_image.length
      Object.keys(list).forEach(async (item, index) => {
        if (index < count) {
          this.uploadImg({file: list[item]})
        }
      })
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let str = this.formData.interview_assessment_image.map(item => item.url);
          str = str.join(',')
          let params = {
            ...this.formData,
            order_id: this.$route.query.id,
            birthday_ext: this.formData.birthday_type === 1 ? this.formData.birthday_y : this.formData.birthday_n,
            interview_assessment_image: str
          }
          this.$_register
              .post('api/recruit/fresh-graduate/update', {...params})
              .then(res => {
                if (res.data.status === 0) {
                  this.$message.success({
                    message: '保存成功',
                    onClose: () => {
                      this.$store.commit('setPage', 1)
                      this.$router.go(-1)
                    }
                  })
                }
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  display: none;
}

::v-deep .el-form .el-input {
  width: 250rem !important;
}

::v-deep .el-form-item__label {
  padding-right: 40rem !important;
}

::v-deep .yyy .el-form-item__label {
  line-height: 72rem !important;
}

::v-deep .el-form .yyy2 .el-input {
  width: 150rem !important;
}

.yyy {
  overflow: hidden;
}

.yyy1 .el-form-item {
  margin-bottom: 0;
}

.knowledge {
  margin-bottom: 24rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .knowledge_box {
    display: flex;
    align-items: center;
    height: 72rem;
    padding: 0 40rem;
    background-color: #f4f4f4;
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}
</style>
